.barraSuperior, .barraSuperior.affix {
  background: var(--secondColor);
  color: var(--backgroundDefault);
  z-index: 999;
  padding: 15px;
  box-shadow: 0px 5px 5px -2px #3a3838;
}
.barraSuperior .logo {
  /*padding-left: 20px;*/
  color: var(--colorText);
}
.barraSuperior .imgMenu #btnMenu {
  cursor: pointer;
  color: var(--backgroundDefault);
}
#menuUser {
  background: var(--secondColor);
  color: var(--backgroundDefault);
  position: fixed;
  right: 0;
  top: 60px;
  height: 100%;
  z-index: 999;
  width: auto;
  padding: 0px;
  visibility: hidden;
  transform: translateX(250px);
  transition: all 0.3s ease;
  box-shadow: 0px 10px 10px -2px var(--colorText);
}
#menuUser.active {
  visibility: visible;
  transform: translateX(0px);
}
#menuUser li{
  padding: 15px 40px 15px 25px;
  list-style: none;
  width: 100%;
  cursor: pointer;
}
#menuUser li.active, #menuUser li:hover{
  background: var(--colorDefault);
  color: var(--secondColor);
}
#menuUser li span,  #menuUser li svg{
  margin-right: 15px;
}
#menuUser li img {
  margin-top: -5px;
}
#logo-barra{
  width: 65px;
}
#titleMenu {
  text-transform: uppercase;
  /*font-weight: bold;*/
  font-size: 20px;
  font-family: 'Font_Bold';
  color: #fff;
}
.bodyDashboard {
  margin: 0px auto 0;
  padding-top: 15px;
  max-width: 750px;
  width: 100%;
  height: 100%;
  max-height: 92vh;
  overflow: scroll;
  overflow-x: hidden;
}
.itensHome {
  width: 100%;
  /*height: 80vh;*/
}
.itensHome #imgUser{
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  border: 3px solid #fff;
  background-color: #fff;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.itensHome .title{
  margin-top: 30px;
  margin-left: 15px;
  font-family: 'Font_Bold';
}
.itemHome {
  padding: 20px 15px;
  margin: 5px;
  box-shadow: 0px 0px 8px 0px rgb(152 152 152);
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 15px;
}
/*.itemHome span{
  color: var(--colorText2);
}*/
.itemHome .itemHomeIcon{
  color: var(--secondColor);
}
.itemHome .title{
  margin-top: 20px;
  color: var(--secondColor);
  /*font-weight: bold;*/
  font-size: 25px;
  margin-left: 0;
  font-family: 'Font_Bold';
}
.itemHome #imgShop {
  width: 280px;
  height: 200px;
  background-size: cover;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  margin: 0px;
}
.itemHome .switchOpenShop {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 27px;
  margin-top: 12px;
  z-index: 10;
}.itemHome .switchShopDelivery {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 27px;
  margin-top: 55px;
  z-index: 10;
} 
.dashboardEditShop {
  margin-bottom: 10px;
  margin-top: 10px;
  list-style: none;
}
.dashboardList .itemDashboardList {
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 15px;
  background: rgb(253, 253, 253);
  /*border: 2px solid rgb(245, 245, 245);*/
  box-shadow: 0px 2px 8px -2px rgb(177, 177, 177);
  list-style: none;
  border-left: 3px solid var(--colorDefault);
}
.userList .itemUserList {
  margin-bottom: 0px;
  margin-top: 8px;
  border-radius: 5px;
  padding: 10px 10px;
  background: rgb(253, 253, 253);
  box-shadow: 0px 2px 5px -2px rgb(177, 177, 177);
  list-style: none;
  border-left: 3px solid var(--colorDefault);
}
.userList label#thumbnail{
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--colorDefault);
  background-color: var(--colorDefaultHover);
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.userList form label {
  font-size: 14px;
  color: #444;
  font-weight: bold;
  margin-bottom: 2px;
}
.dashboardList .itemDashboardListActive{
  box-shadow: 0px 0px 10px 1px #636363;
  /*paddingbox-shadow: 0px 0px 2px 3px var(--colorDefault);
  border: 0;*/
}
.headList {
  cursor: pointer;
}
.dashboardList .itemDashboardList .iconItem, .musicList .itemMusicList .iconItem{
  margin: 5px;
  color: dimgrey;
}
.dashboardList .itemDashboardList .edit{
  color: #01d401;
}
.dashboardList .itemDashboardList .delete{
  color: #888888;
}
.dashboardList .itemDashboardList .itemImg {
  width: 100%;
  max-width: 160px;
}
.dashboardList .itemDashboardList label#thumbnail, label#thumbnailShop{
  display: flex;
  width:100%;
  height: 350px;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ddd;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  margin-top: 8px;
}
.dashboardList .itemDashboardList label#background{
  width:100%;
  height: 350px;
  margin-bottom: 1rem;
  border: 1px dashed #ddd;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.dashboardList .itemDashboardList .itemDashboardListSwitch {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 45px;
  margin-top: -60px;
  z-index: 10;
}
.linkListItem {
  color: var(--colorDefault);
  text-decoration: underline;
  font-weight: bold;
  margin-left: 5px;
  /*margin-top: -15px;
  margin-bottom: 10px;*/
}
audio {
  margin-left: 15px;
  margin-right: 15px;
  height: 40px;
}
#audio-upload[type="file"] {
  display: none;
}
label#thumbnail input, label#background input, label#thumbnailShop input{
  display: none;
}
label#thumbnail.has-thumbnail, label#background.has-thumbnail, label#thumbnailShop.has-thumbnail{
  border: 0 !important;
}
label#thumbnail.has-thumbnail img, label#background.has-thumbnail img, label#thumbnailShop.has-thumbnail img{
  display: none;
}
.dashboardList input[type=checkbox] {
  -ms-transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
  margin: 21px 12px 0px 0px;
  cursor: pointer;
  /*filter: invert(100%) hue-rotate(80deg) brightness(1.7);*/
  filter: hue-rotate(500deg); /* 250: green*/ 
}
.title {
  color: var(--colorText);
  /*font-weight: 700;*/
  font-size: 22px;
  width: 100%;
  font-family: 'Font_Bold';
}
.titleDate {
  color: var(--colorText);
  /*font-weight: 700;*/
  font-size: 22px;
  width: auto;
  font-family: 'Font_Bold';
  cursor: pointer;
  background: var(--backgroundDefault);
  border: 0;
  text-align: center;
  margin-left: -15px;
}
.iconDate {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 8px;
  cursor: pointer;
  color: var(--colorText);
}
.labelFilter {
  color: var(--colorText);
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;
  margin-right: 8px;
}
.iconFilter {
  color: var(--colorText);
}
.btnNew {
  margin: 5px 0px;
  border-radius: 50%;
  border: none;
  padding: 5px;
  width: 50px;
  height: 50px;
  background: var(--secondColor);
  color: #fff;
  box-shadow: 0px 2px 10px -2px #bfbfbf;
}
.btnNew i {
  display: block;
}
.btnDeleteImg {
  position: absolute;
  margin-top: 92px;
  margin-left: 27px;
  border-radius: 50%;
  border: none;
  padding: 5px;
  width: 30px;
  height: 30px;
  background: #f8f8f8;
  color: var(--colorDefault);
  box-shadow: 0px 2px 10px -2px #bfbfbf;
}
.iconInputRight{
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 25px;
  margin-top: 13px;
  color: #ababab;
}
.contentEditor {
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
.contentEditor .inputEditor {
  padding: 15px;
}
.imagesPreview {
  display: inline-block;
  width: 130px;
  height: 130px;
  margin: 10px;
  border-radius: 10px;
  background-size: cover !important;
  background-position: center !important;
  cursor: pointer;
}
#labelImages {
  cursor: pointer;
}
#formNotification{
  width: 100%;
}
.emotionSelected {
  margin-bottom: 5px;
  margin-left: 7px;
  padding: 4px;
  background-color: #ddd;
  border-radius: 5px;
  /*width: 80px;*/
}
.emotionSelected .btnDeleteEmotion {
  margin-left: 8px;
  cursor: pointer;
}
/* PRODUCT */
#productList .dashboardList .itemDashboardList label#thumbnail{
  display: flex;
  width:100%;
  /*max-width: 250px;*/
  max-height: 200px;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ddd;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.itemSize {
  margin-bottom: 8px;
  margin-left: 7px;
  padding: 4px;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid var(--colorDefault);
  padding: 10px 25px 10px 20px;
  font-size: 14px;
  position: relative;
  font-weight: bold;
}
.itemSizeShop {
  width: 100%;
  margin-right: 15px;
  justify-content: space-between;
  align-items: center;
  padding: 30px 25px 10px 20px;
}
.itemSize .btnInStock {
  margin-top: 4px;
  margin-left: 8px;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
}
.itemSizeShop input[type=checkbox] {
  transform: scale(1.2);
  margin: 0 5px 0px 0px;
}
.itemSize .btnDeleteSize {
  margin-left: 12px;
  cursor: pointer;
  color: var(--colorDefault);
  font-size: 16px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 5px;
}
.itemListHorizontal {
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 2px 8px -2px rgb(177 177 177);
  --webkit-box-shadow: 0px 2px 8px -2px rgb(177 177 177);
  margin: 0 10px;
  cursor: pointer;
}
.itemListHorizontal p {
  padding: 15px;
}
.itemListHorizontal .imgItemListHorizontal {
  height: 170px;
  width: 210px;
  margin-top: 15px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
}
.arrowLeft {
  z-index: 99;
  font-size: 25px;
  position: absolute;
  left: 0;
  margin-top: -25px;
  cursor: pointer;
  margin-left: 5px;
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0px 2px 8px -2px rgb(177 177 177);
  --webkit-box-shadow: 0px 2px 8px -2px rgb(177 177 177);
  padding: 4px 3px 4px 9px;
}
.arrowRight {
  z-index: 99;
  font-size: 25px;
  position: absolute;
  right: 0;
  margin-top: -25px;
  cursor: pointer;
  margin-right: 5px;
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0px 2px 8px -2px rgb(177 177 177);
  --webkit-box-shadow: 0px 2px 8px -2px rgb(177 177 177);
  padding: 4px 3px 4px 13px;
}
/* ORDERS */
.itensOrder{
  max-height: 400px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.itemOrder {
  margin-bottom: 12px;
}
.imgItemOrder {
  height: 75px;
  width: 90px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
}
.iconSearchInput {
  color: #c1c1c1;
  margin: 20px 35px;
  position: absolute;
  right: 0;
  top: 0;
}
.barraBottom {
  margin-top: 15px;
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #fff;
  padding-top: 12px;
  border-top: 1px solid #efefef;
  margin-bottom: -12px;
}
/*.priceItem {
  position: absolute;
  width: 90px;
  margin-top: -45px;
  background: #18c103;
}
.priceItem label {
  font-size: 12px;
}*/
/* Mobile Layout: 320px. */

/* react-datalist-input */
.react-datalist-input__container > * {
  box-sizing: border-box;
}

.react-datalist-input__container {
  width: 100%;
  position: relative;
  --shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --gray: rgb(200, 200, 200);
  --padding: 5px;
  --border-radius: 5px;
  --border: 1px solid var(--gray);
}

.react-datalist-input__textbox {
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  padding: var(--padding);
  border: var(--border);
  border-radius: var(--border-radius);
}

.react-datalist-input__listbox {
  width: 100%;
  position: absolute;
  margin: 2px 0 0 0;
  padding: 0;
  border: var(--border);
  border-radius: var(--border-radius);
  background-color: white;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  box-shadow: var(--shadow);
  z-index: 10;
}

.react-datalist-input__listbox-option {
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: var(--padding);
}

.react-datalist-input__listbox-option:focus {
  background-color: var(--gray);
}

.react-datalist-input__listbox-option:hover {
  background-color: var(--gray);
}

/*# sourceMappingURL=styles.css.map */


@media only screen and (max-width: 767px) {
  /*#logo-barra{
    width: 60px;
  }*/
  .dashboardList .itemDashboardList label#thumbnail, label#thumbnailShop{
    width:100%;
    height: 160px;
  }
  .itensHome {
    height: auto;
  }
  .itemHome #imgShop {
    width: 110px;
    height: 150px;
    display: none;
  }
  .titleDate {
    text-align: left;
    margin-left: 0px;
  }
}