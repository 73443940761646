
#homeIndex h1, #homeIndex h2, #homeIndex h3, #homeIndex h4, #homeIndex h5, #homeIndex h6 {
  color: #fff;
  font-family: 'Font_Bold';
}
#menu {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgb(0, 0, 0, 0.9);
  height: 100vh;
  width: 100%;
  z-index: 99;
}
#menu li {
  margin-bottom: 12px;
}
#menu li a {
  font-size: 28px;
  color: white;
  /*font-family: "Font-Bold";*/
}
.btnMenu {
  padding: 8px 12px;
  z-index: 990;
  position: inherit;
}
.btnMenu i{
  margin-top: 5px;
  font-size: 1.5em;
}
#homeIndex {
  /*background-image: url('../../assets/background.jpg');
  background: var(--colorDefault);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  padding-top: 30px;*/
}

#homeIndex .infoTop {
  display: block;
  background: rgb(21, 21, 21);
  font-family: "Font-Regular" !important;
  padding: 5px 15px !important;
  font-size: 14px !important;
  color: var(--colorDefault) !important;
  margin-top: -12px;
}

#homeIndex #navbar.onScroll .infoTop {
  margin-top: -5px;
}

#homeIndex #navbar{
  padding: 12px 0;
  width: 100%;
  top: 0;
  margin-top: 15px;
  position: fixed;
  z-index: 999;
}

#homeIndex #navbar.onScroll{
  padding: 15px 0;
  position: fixed;
  width: 100%;
  background-color: var(--secondColor);
  box-shadow: 0px 0px 10px -2px var(--colorDefault);
  --webkit-box-shadow: 0px 0px 10px -2px var(--colorDefault);
  margin-top: 0px;
}

#homeIndex .logo img{
  max-width: 180px;
}
#homeIndex .onScroll .logo img{
  max-width: 120px;
}

#homeIndex .logo h1{
  font-size: 45px;
  font-weight: bold;
  color: var(--colorDefault);
  padding: 15px 0;
  margin-bottom: 0;
  cursor: pointer;
}

#homeIndex .bodyIndex {
  display: flex;
  flex: 1;
  background-image: url('../../assets/background.jpg');
  width: 100%;
  height: 88vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
}

.bodyIndex .overlay {
  display: flex;
  flex: 1;
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.85));
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.bodyIndex h1 {
  color: var(--colorDefault) !important;
  font-family: 'Font_Bold' !important;
  font-size: 5rem;
}

#homeIndex #navbar a {
  font-family: 'Font-Bold';
  text-transform: none;
  font-size: 20px;
  padding: 12px 20px;
  color: var(--white);
}

#homeIndex #navbar a:hover {
  color: var(--colorDefault);
}

#homeIndex #navbar a.btn-default{
  color: #fff;
  font-size: 18px;
  padding: 12px 25px;
  font-weight: 500;
}

#homeIndex .bodyIndex p {
  margin-top: 15px;
  font-size: 20px;
}

#homeIndex .bodyIndex .card p{
  color: #004db9;
  margin-bottom: 0;
  padding: 15px;
  font-weight: 600;
  font-size: 18px;
}

#homeIndex .bodyIndex button {
  padding: 12px 35px;
}
#homeIndex p, #homeIndex span{
  font-size: 1.1rem;
  color: #fff;
}
#homeIndex .footerIndex {
  padding: 15px 0;
  background: #f8f8f8;
}
#homeIndex .footerIndex p, #homeIndex .footerIndex a {
  color: #353535;
  text-transform: none;
  margin-bottom: 8px;
}
#homeIndex #divVideo {
  border-radius: 15px;
  box-shadow: 0px 25px 25px -25px #444;
  --webkit-box-shadow: 0px 25px 25px -25px #444;
  /*border: 2px solid #969696;*/
}
.divider {
  background-color: var(--colorDefault);
  color: #fff;
  padding: 20px 0px;
}
.divider p, .divider h3 {
  color: #fff !important;
  margin-bottom: 0px;
}
#bodyHowWorks, #bodyGalery, #bodyPlans, #bodyWarranty {
  padding: 80px 0;
  background-color: var(--backgroundDefault);
  /*margin-top: -100px;*/
}
#bodyHowWorks, #bodyWarranty {
  background-color: var(--backgroundDefault);
  color: #fff;
}
#bodyHowWorks h1, #bodyWarranty h1, #bodyWarranty .title, #bodyWarranty p {
  color: #fff;
}
#bodyHowWorks .title, #bodyKnow .title, #bodyFranchise .title {
 margin-top: 15px;
 font-size: 20px;
}
#bodyHowWorks img {
  padding: 30px;
}
#bodyHowWorks .itemHowWorks {
  padding: 20px 15px;
  border-radius: 8px;
  background: white;
  color: var(--colorText);
  margin-bottom: 15px;
}
#bodyHowWorks .itemHowWorks:hover {
  box-shadow: 0px 0px 10px 1px var(--shadow);
  --webkit-box-shadow: 0px 0px 10px 1px var(--shadow);
}
#bodyHowWorks .itemHowWorks .iconItemHowWorks {
  color: var(--colorDefault);
  padding: 15px 10px;
}
#bodyHistory p, #bodyKnow p, #bodyFranchise p {
  text-align: justify;
  padding: 0rem 1rem;
}
#bodyKnow {
  display: flex;
  flex: 1;
  background-image: url('../../assets/background04.jpeg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
}
#bodyKnow .overlay {
  display: flex;
  flex: 1;
  background-color: rgba(0,0,0,0.7);
  width: 100%;
  /*height: 100vh;*/
  justify-content: center;
  align-items: center;
  padding: 80px 0;
}
#bodyKnow img {
  border-radius: 20px;
  box-shadow: 0px 0px 25px 5px var(--colorDefault);
}
#bodyKnow h4{
  color: var(--colorDefault);
}
#bodyKnow .itemKnow {
  box-shadow: 0px 0px 10px 1px var(--shadow);
  --webkit-box-shadow: 0px 0px 10px 1px var(--shadow);
  padding: 8px 12px;
  border-radius: 8px;
  background: white;
  margin-bottom: 15px;
}
#bodyKnow .itemKnow .title{
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 5px;
}
#bodyKnow .itemKnow span{
  font-size: 14px;
}
#bodyKnow .itemKnow i{
  color: var(--colorDefault);
}

#bodyHistory {
  display: flex;
  flex: 1;
  background-image: url('../../assets/background06.jpeg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
}
#bodyHistory .overlay {
  display: flex;
  flex: 1;
  background-color: rgba(0,0,0,0.75);
  width: 100%;
  /*height: 100vh;*/
  justify-content: center;
  align-items: center;
  padding: 80px 0;
}
#bodyHistory .divImage{
  padding: 15px;
}
#bodyHistory .divImage img {
  border-radius: 50%;
  box-shadow: 0px 0px 25px 2px var(--colorDefault);
  margin-bottom: 15px;
}
#bodyHistory h4{
  color: var(--colorDefault);
}

#bodyFranchise {
  display: flex;
  flex: 1;
  background-image: url('../../assets/background03.jpeg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
}
#bodyFranchise .overlay {
  display: flex;
  flex: 1;
  background-color: rgba(0,0,0,0.8);
  width: 100%;
  /*height: 100vh;*/
  justify-content: center;
  align-items: center;
  padding: 80px 0;
}
#bodyFranchise img {
  border-radius: 20px;
  box-shadow: 0px 0px 25px 2px var(--colorDefault);
  margin-bottom: 15px;
}
#bodyFranchise h4{
  color: var(--colorDefault);
}

#bodyPlans {
  color: 'initial'
}
#bodyPlans .itemPlan {
  position: relative;
  box-shadow: 0px 0px 10px 1px var(--shadow);
  --webkit-box-shadow: 0px 0px 10px 1px var(--shadow);
  padding: 25px 15px;
  border-radius: 8px;
  background: white;
  color: initial;
  margin-bottom: 15px;
  border: 2px solid #fff;
}
#bodyPlans .itemPlan .title {
  font-size: 28px;
  color: initial;
}
#bodyPlans .itemPlan .description {
  font-size: 16px;
  color: initial;
  margin-top: -15px;
}
#bodyPlans .itemPlan:hover, #bodyPlans .itemPlan.active {
  border: 2px solid var(--colorDefault);
  box-shadow: 0px 0px 20px -2px var(--colorDefault);
  --webkit-box-shadow: 0px 0px 20px -2px var(--colorDefault);
}
#bodyPlans .itemPlan li i {
  font-size: 20px;
  color: var(--colorDefault);
  margin-right: 5px;
}
#bodyPlans .itemPlan li b, #bodyPlans .itemPlan h1 {
  color: var(--colorDefault);
}
#bodyPlans .itemPlan .priceMouth {
  /*padding: 0px 0px 10px 0px !important;*/
}
#bodyPlans .itemPlan .priceMouth .big {
  font-size: 40px;
  color: var(--colorDefault);
}
#bodyPlans .itemPlan .priceMouth .oldPrice {
  font-size: 24px;
  color: #929292;
  text-decoration: line-through;
  font-weight: bolder;
}
#bodyPlans .itemPlan .obs {
  font-size: 12px; 
  font-style: italic;
  margin: 15px, 0;
  color: initial;
}
#bodyPlans .imgDiscount {
  width: 80px;
  position: absolute;
  right: 0;
  margin-top: -20px;
  z-index: 8;
}
#bodyPlans .imgFreteGratis{
  width: "100%";
  max-width: 250px;
}
#bodyPlans .emBreve {
  position: absolute;
  left: 0;
  right: 0;
  width: 102%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  background: rgb(0 0 0 / 75%);
  height: 101%;
  flex: 1 1;
  margin: -5px;
  margin-top: -30px;
  border-radius: 5px;
  z-index: 9;
}
#bodyPlans .emBreve img{
  max-width: 250px;
  margin-top: 120px;
}
#bodyPlans .divButtonSelect {
  display: inline-flex;
  width: 100%;
  background: #fff;
  /*border: 1px solid #ced4da;*/
  border-radius: 30px;
  margin-bottom: 60px;
  box-shadow: 0px 0px 10px -1px var(--shadow);
  --webkit-box-shadow: 0px 0px 10px -1px var(--shadow);
}
#bodyPlans .divButtonSelect .itemButtonSelect {
  width: 50%;
  padding: 10px 15px;
  border-radius: 30px;
  cursor: pointer;
}
#bodyPlans .divButtonSelect .itemButtonSelect.active {
  background: var(--colorDefault);
  color: #fff;
}

#bodyPlans .image-gallery .image-gallery-slides {
  box-shadow: none;
  --webkit-box-shadow: none;
  border-radius: 10px;
  margin-bottom: 5px;
}
#bodyPlans .image-gallery .image-gallery-image {
  max-height: 350px;
}

#bodyWarranty img {
  margin: 15px;
}

.video-galery {
  width: 100%;
  min-height: 620px;
}

#bodyShops {
  display: flex;
  flex: 1;
  background-image: url('../../assets/maps.JPG');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
}
#bodyShops .overlay {
  display: flex;
  flex: 1;
  background-color: rgba(0,0,0,0.85);
  width: 100%;
  /*height: 100vh;*/
  justify-content: center;
  align-items: center;
  padding: 80px 0;
}
#bodyShops .card {
  background-color: var(--colorDefault);
  box-shadow: 0px 0px 10px 0px var(--colorDefault);
  --webkit-box-shadow: 0px 0px 10px 0px var(--colorDefault);
}
#bodyShops .card img {
  border-radius: .25rem;
  filter: brightness(0.15);
}
#bodyShops .card h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
#bodyShops .card p {
  color: var(--secondColor);
}
#bodyShops p, #bodyShops span {
  margin-bottom: 0px;
}

#footer {
  background: var(--secondColor);;
  color: var(--colorText);
  padding: 40px 0;
}

#footer .logo image{
  width: 80px;
}

#footer p{
  font-size: 12px;
}

#footer2 {
  background:#1f1f1f;
  color: var(--colorText);
  padding-top: 15px;
}
#footer2 p{
  font-size: 12px;
}

.lineDivider {
  width: 100%;
  height: 320px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -50px;
}

.iconCheck {
  font-size: 20px;
  color: var(--colorDefault);
  margin-right: 5px;
  margin-left: 15px;
}

/* MAILCHIMP */
#formMailchimp input{
  font-family: 'Font-Regular';
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 1.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-bottom: 15px;
}
#formMailchimp button {
  font-size: 18px;
  border: 0;
  border-radius: 5px;
  background: var(--colorDefault);
  color: #fff;
  cursor: pointer;
  padding: 12px 15px;
  margin-bottom: 15px;
}
#formMailchimp .msg-alert {
  text-align: center;
}

.divBtnWhatsApp {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 15px;
}

.btnWhatsApp {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 0;
  background: #4ae666;
  box-shadow: 0px 0px 8px -1px var(--shadow);
  --webkit-box-shadow: 0px 0px 8px -1px var(--shadow);
}

.btnWhatsApp:hover {
  color: #2bcc46;
}

.cupom {
  background: #e4e4e4;
  color: #000;
  padding: 15px 30px;
  border-style: dashed;
  border-radius: 5px;
  border-color: gray;
  border-width: 2px;
  font-weight: bold;
  font-size: 20px;
}

/* Mobile Layout */
@media only screen and (max-width: 767px) {
  #homeIndex #navbar{
    margin-top: 10px;
  }
  #homeIndex .bodyIndex {
    height: 80vh;
  }
  #homeIndex .logo img {
    max-width: 130px;
  }
  .itensHome {
    height: auto;
  }
  .lineDivider {
    width: 100%;
    height: 200px;
    background-image: none !important;
  }
  .video-galery {
    width: 100%;
    min-height: 350px;
  }
  /*#homeIndex a {
    color: white;
    text-transform: uppercase;
    font-size: 10px;
  }*/
}