@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@font-face {
  font-family: 'Font_Regular';
  src: local('Font_Regular'), url(./assets/fonts/Rotis-Sans-Serif.ttf) format('opentype');
}
@font-face {
  font-family: 'Font_Bold';
  src: local('Font_Bold'), url(./assets/fonts/Rotis-Sans-Bold.otf) format('opentype');
  font-weight: bold;
}
/* Variáveis*/
:root {
  --colorDefault: #fbb515;
  --colorDefaultHover: #d69501;
  --secondColor: #050504;
  --colorText: #5d5d5d;
  --colorText2: #919191;
  --backgroundDefault: #f8f8f8;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: var(--colorDefault); }

html, body, #root {
  min-height: 100%;
}

body {
  background: #f8f8f8;
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;
  font-size: 14px;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Font_Bold';
}
body, p, span, input, select, textarea, label, button{
  font-family: 'Font_Regular';
}
a:hover {
  text-decoration: none;
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: none !important;
}
.form-control {
  padding: 0.375rem 0.75rem !important;
  padding-top: 20px !important;
  height: 55px;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #c7c7c7;
  outline: 0;
  box-shadow: none;
}
button:focus {
  outline: none;
}
form {
  display: flex;
  flex-direction: column;
}
form label {
  font-size: 14px;
  color: #2a2a2a;
  font-weight: bold;
  margin-bottom: 0px;
}
.labelInput {
  position: absolute;
  margin-left: 10px;
  margin-top: 2px;
  font-size: 12px;
}
.labelImage {
  font-size: 14px;
  background: white;
  /*float: left;*/
}
form label span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}
form input {
  border: 1px solid #ddd;
}
.btn {
  border: 0;
  border-radius: 30px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  padding: 15px 25px;
  text-transform: uppercase;
  font-size: 14px;
}
.btn-default {
  background: var(--colorDefault);
  color: #fff;
}
.btn-default:hover {
  background: var(--colorDefaultHover);
  color: #fff;
}
.btn-default-outline {
  background: var(--secondColor);
  border: 3px solid var(--colorDefault);
  color: var(--colorDefault);
}
.btn-default-outline:hover {
  background: var(--colorDefault);
  border: 3px solid var(--secondColor);
  color: var(--secondColor);
}
.btn-delete {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}
.btn-delete:hover {
  background: #1d1d1d;
  border-color: #1d1d1d;
  color: #fff;
}
.btnCloseModal {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
  z-index: 99;
  color: var(--secondColor);
  border: 0;
  background: transparent;
  padding: 5px;
  font-size: 25px;
}
.btnLink {
  color: var(--colorDefault);
  border: 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.0);
}
.btnTrash {
  background: none;
  color: var(--secondColor);
  border: 0;
  outline: 0;
}

.hidden {
  display: none;
}

.opacityDefault {
  opacity: 0.6;
}

.v-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.containerLoading {
  position: fixed;
  z-index: 99;
  background: rgba(0,0,0,0.85);
  flex: 1;
  height: 100%;
  width: 100%;
  margin-left: -15px;
}

/* Checkbox Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
}
.switch-absolute {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
  right: 0;
  margin-right: 12px;
  margin-top: -8px;
  z-index: 99;
}
.switch input, .switch-absolute input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: var(--colorDefault);
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
} 
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  position: initial !important;
}
.react-datepicker__navigation{
  margin-top: 12px;
}

.hidden-xs {
  display: initial;
} 
.visible-xs {
  display: none;
} 
@media only screen and (max-width: 552px) {
  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: initial;
  }
}
.print-me { display: none;}
@media print { 
  .no-print  { display: none !important;}
  .print-me  { display: block !important;}
}