#login {
  display: flex;
  flex: 1;
  background-image: url('../../assets/background.jpg');
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
}
#login .overlay {
  display: flex;
  flex: 1;
  background-color: rgba(0,0,0,0.7);
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.login {
  width: 100%;
  max-width: 550px;
  /*margin: 150px auto 0;
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;*/
}
#logo {
  width: 220px;
  margin-bottom: 30px;
}
.login .content {
  margin-top: 20px;
  border-radius: 10px;
  padding: 30px;
  margin: 15px;
  /*border: 2px solid rgb(245, 245, 245);*/
  /*box-shadow: 0px 15px 10px -6px rgb(177, 177, 177);
  text-align: center;*/
}
.content p {
  color: rgb(175, 175, 175);
}
.content h1 {
  color: rgb(255, 255, 255);
  font-family: 'Font_Bold';
}
